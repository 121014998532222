<template>
<div>
    <div>
        <h2 class="m-title">
            <div class="txt"></div>
            <div class="btn-area">
                <v-btn color="primary" @click="dialog_batch = true">批次修改</v-btn>
            </div>
        </h2>
        <div id="real-table"></div>
    </div>

<!-- dialog -->
<!-- dialog_batch -->
<v-dialog v-model="dialog_batch" max-width="700px">
    <v-card>

        <v-card-title class="title-bordb">
        批次修改
        </v-card-title>
    
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-select
                        v-model="selectedIdName"
                        :items="selected_id_items"
                        label="群組"
                        multiple
                        outlined
                        hide-details
                        item-text="device"
                        item-value="device"
                    >
                        <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                        >
                            <v-list-item-action>
                                <v-icon :color="selectedIdName.length > 0 ? 'indigo darken-4' : ''">
                                    {{ filterIcon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    全選
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                            <v-icon>
                                mdi-food-apple
                            </v-icon>
                            </v-list-item-avatar>
                
                        </v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12">
                <v-textarea 
                    label="批次填入感測器編號(逐行分開)"
                        rows="2" outlined
                        v-model="textarea_id_items"
                        @change="idChangeSelected()"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        :items="colname.items"
                        label="欄位名稱" 
                        item-text='name'
                        item-value='field' outlined @change="colnameChange"
                        v-model="selected_field"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <div v-if="colname.field == 'text'">
                        <v-text-field
                            :label="colname.label"
                            outlined
                            dense
                            :placeholder="colname.label"
                            hide-details
                            v-model="selected_val"
                        ></v-text-field>
                    </div>
                    <div v-if="colname.field == 'select'">
                         <v-select
                            :items="colname.subselect"
                            :label="colname.label" 
                            item-text='name'
                            item-value='value' outlined
                            v-model="selected_val"
                        ></v-select>
                    </div>
                    <div v-if="colname.field == 'multiselect'">
                         <v-select
                            v-model="selected_val"
                            :items="colname.subselect"
                            :label="colname.label" 
                            multiple
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="value"
                        >
                            <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggle_multi"
                            >
                                <v-list-item-action>
                                    <v-icon :color="selected_val.length > 0 ? 'indigo darken-4' : ''">
                                        {{ filterMultiIcon }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        全選
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:append-item>
                            </template>
                        </v-select>
                    </div>

                </v-col>
            </v-row>
            </v-container>
        <div class="close-btn-area">
            <v-btn
            class="ct-btn icononly"
            text
            @click="dialog_batch = false"
            >
                <div class="icon-close">X</div>              
            </v-btn>
        </div>

        <v-divider></v-divider>
        <v-card-actions class="justify-end">
            <v-btn color="primary" @click="saveBatch()">
            送出
            </v-btn>
            
        </v-card-actions>
    </v-card>
</v-dialog><!-- dialog_batch -->


<!-- dialog_single -->
<v-dialog v-model="dialog_single" max-width="1000px">
    <v-card class="small-input-height">

        <v-card-title class="title-bordb">
        更多
        </v-card-title>
    
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="感測器編號"
                        :value="single_station.id"
                        outlined
                        dense
                        hide-details
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="名稱"
                        :value="single_station.devicename"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.devicename"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="監測地區"
                        :value="single_station.area"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.area"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="監測種類"
                        :value="single_station.areatype"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.areatype"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="經度"
                        :value="single_station.lat"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.lat"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="緯度"
                        :value="single_station.lon"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.lon"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="高度"
                        :value="single_station.alt"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.alt"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        :items="single_station.silent_list"
                        dense
                        label="靜音" 
                        item-text='name'
                        item-value='value' outlined
                        v-model="single_station.silent"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        :items="single_station.types_list"
                        dense
                        label="類型" 
                        item-text='name'
                        item-value='value' outlined
                        v-model="single_station.types"
                    ></v-select>
                </v-col>


                <v-col cols="12" sm="6">
                    <v-text-field
                        label="PM2.5校正係數"
                        :value="single_station.cala"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.cala"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="PM2.5 校正截距"
                        :value="single_station.calb"
                        outlined
                        dense
                        hide-details
                        type="number"
                         v-model="single_station.calb"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="溫度校正係數"
                        :value="single_station.temp_cala"
                        outlined
                        dense
                        hide-details
                        type="number"
                         v-model="single_station.temp_cala"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="溫度校正截距"
                        :value="single_station.temp_calb"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.temp_calb"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="相對濕度校正係數"
                        :value="single_station.rh_cala"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.rh_cala"
                    ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="6">
                    <v-text-field
                        label="相對濕度校正截距"
                        :value="single_station.rh_calb"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.rh_calb"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="PM10校正係數"
                        :value="single_station.pm10_cala"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.pm10_cala"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="PM10校正截距"
                        :value="single_station.pm10_calb"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.pm10_calb"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="告警群組"
                        :value="single_station.gid"
                        outlined
                        dense
                        hide-details
                        type="number"
                        v-model="single_station.gid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        :items="single_station.env_list"
                        dense
                        label="場域" 
                        item-text='name'
                        item-value='value' outlined
                        v-model="single_station.env"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="鄉鎮區"
                        :value="single_station.town"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.town"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="道路名"
                        :value="single_station.road"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.road"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="著名標的"
                        :value="single_station.landmark"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.landmark"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="感測目標"
                        :value="single_station.target"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.target"
                    ></v-text-field>
                </v-col>
                <!--<v-col cols="12" sm="6">
                    <v-text-field
                        label="校正代表"
                        :value="single_station.representative"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.representative"
                    ></v-text-field>
                </v-col>-->
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="獨立校正"
                        :value="single_station.independent"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.independent"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select 
                        label="環保署"
                        v-model="single_station.nearest_epa_selected"
                        dense
                        outlined
                        :items="single_station.nearest_epa"
                        item-text="name"
                        item-value="value"
                        >
                    </v-select>

                </v-col>
                <v-col cols="12" sm="6">
                    <v-select 
                        label="氣象站"
                        v-model="single_station.nearest_cwb_selected"
                        dense
                        outlined
                        :items="single_station.nearest_cwb"
                        item-text="name"
                        item-value="value"
                        >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="群組"
                        :value="single_station.subset"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.subset"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="上傳編碼"
                        :value="single_station.iot_name"
                        outlined
                        dense
                        hide-details
                        v-model="single_station.iot_name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                        <v-select
                            v-model="single_station.rules"
                            :items="single_station.rules_items"
                            label="規則"
                            multiple
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="value"
                        >
                            <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggle_rules"
                            >
                                <v-list-item-action>
                                    <v-icon :color="single_station.rules.length > 0 ? 'indigo darken-4' : ''">
                                        {{ filterRulesIcon }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        全選
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:append-item>
                            </template>
                        </v-select>

                </v-col>
            </v-row>
            </v-container>
        <div class="close-btn-area">
            <v-btn
            class="ct-btn icononly"
            text
            @click="dialog_single = false"
            >
                <div class="icon-close">X</div>              
            </v-btn>
        </div>

        <v-divider></v-divider>
        <v-card-actions class="justify-end">
            <v-btn color="primary" @click="saveSingleDevice()">
            送出
            </v-btn>
            
        </v-card-actions>
    </v-card>
</v-dialog><!-- dialog_single -->



<!-- dialog -->
</div>
    
</template>

<script>
import { mdiMessageDraw,mdiMapMarkerCircle,mdiFilter,mdiMapLegend,mdiShape,mdiCity,mdiClockTimeTwoOutline,mdiClose
,mdiCheckboxBlankOutline,mdiCheckboxMarked } from '@mdi/js';
import axios from 'axios';
import config from '@/config.js';

import MyComponent from './Device.vue'

var tabledata = [];
var table;
var new_json_epa=[];
var new_json_cwb=[];
var new_json_rules=[];

export default{
    components: {
        mdiMessageDraw,
        mdiMapMarkerCircle,
        mdiFilter,
        mdiMapLegend,
        mdiShape,
        mdiCity,
        mdiClockTimeTwoOutline,
        mdiClose,
        mdiCheckboxBlankOutline
    },
    name: 'Info',
    data: () => ({
        icons: {
            mdiMessageDraw,
            mdiMapMarkerCircle,
            mdiFilter,
            mdiMapLegend,
            mdiShape,
            mdiCity,
            mdiClockTimeTwoOutline,
            mdiCheckboxBlankOutline,
            mdiCheckboxMarked
        },
        dialog_batch: false,
        colname:{
            items: [{
                'name':'監測地區',
                'field':'地區名稱-text-area',
                },
                {
                'name':'監測種類',
                'field':'種類名稱-text-areatype'
                },
                {
                'name':'鄉鎮區',
                'field':'鄉鎮區名稱-text-town'
                },
                {
                'name':'道路名',
                'field':'道路名稱-text-road'
                },
                {
                'name':'著名標的',
                'field':'著名標的名稱-text-landmark'
                },
                {
                'name':'場域',
                'field':'測試區-select-env'
                },
                {
                'name':'感測目標',
                'field':'感測目標名稱-text-target'
                },
                {
                'name':'經度',
                'field':'經度-text-lon'
                },
                {
                'name':'緯度',
                'field':'緯度-text-lat'
                },
                {
                'name':'高度',
                'field':'高度-text-alt'
                },
                {
                'name':'環保署',
                'field':'環保署測站-select-nearest_epa'
                },
                {
                'name':'獨立校正',
                'field':'獨立校正-text-independent'
                },
                {
                'name':'氣象站',
                'field':'氣象站-select-nearest_cwb'
                },
                {
                'name':'規則',
                'field':'規則-multiselect-rules'
                }
            ],
            field:"",
            label:"",
            subselect:[],
        },
        selected_id_items: [],
        selectedIdName: [],
        selectedRules: [],
        selected_rules_items: [],
        textarea_id_items: "",
        selected_field:"",
        selected_val:"",
        dialog_single:false,
        single_station:{
            num:0, //資料庫編號
            id:"",
            devicename:"",
            area:"",
            areatype:"",
            silent:"",
            silent_list:[{
                name:"關閉",
                value:"0"
            },{
                name:"開啟",
                value:"1"
            }],
            types:"",
            types_list:[{
                name:"第一類",
                value:"1"
            },{
                name:"第二類",
                value:"2"
            },{
                name:"第三類",
                value:"3"
            },{
                name:"第四類",
                value:"4"
            },{
                name:"第五類",
                value:"5"
            }],
            lat:"",
            lon:"",
            alt:"",
            cala:"",
            calb:"",
            temp_cala:"",
            temp_calb:"",
            rh_cala:"",
            rh_calb:"",
            pm10_cala:"",
            pm10_calb:"",
            gid:"",
            env:"",
            env_list:[],
            town:"",
            road:"",
            landmark:"",
            target:"",
            representative:"",
            independent:"",
            nearest_epa:[],
            nearest_cwb:[],
            subset:"",
            iot_name:"",
            nearest_epa_selected:"",
            nearest_cwb_selected:"",
            rules_items:[],
            rules:[]
        }
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(11)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){

        var self=this;// assign context to self variable

        $(function() {
            $(".nav_mainTitle").text("裝置管理");
        })
        var selectedIdAry=[];
        var optIconFunction = function(cell, formatterParams){ 
                return "<div class='btn-group text-center'><a class='btn btn-icon'><span class='icon edit'></span></a></div>"
            };

                var optIconFunction2 = function(cell, formatterParams){ 
                return "<div class='btn-group text-center'><a class='btn btn-icon'><span class='icon more'></span></a></div>"
            };

        const apiurl=config.geturl(config.API.infostation,{});
        axios.get(apiurl).then(response => {
            if(response.data !== null && response.data !== ""){
                tabledata = response.data;

                for(var k in tabledata) {
                    selectedIdAry.push({
                        device:tabledata[k].device,
                        num:tabledata[k].num
                    });
                }
                this.selected_id_items = selectedIdAry;
                
                
                //console.log(tabledata);
                table = new Tabulator("#real-table", {
                    height:"auto",
                    locale:"zh-tw",
                    langs:{
                        "zh-tw":{
                            "pagination":{
                                "page_size":"頁數", //label for the page size select element
                                "first":"第一頁", //text for the first page button
                                "first_title":"第一頁", //tooltip text for the first page button
                                "last":"最後一頁",
                                "last_title":"最後一頁",
                                "prev":"上一頁",
                                "prev_title":"上一頁",
                                "next":"下一頁",
                                "next_title":"下一頁",

                            },
                        }
                    },
                    data:tabledata,           //load row data from array
                    layout:"fitColumns",      //fit columns to width of table
                    responsiveLayout:"hide",  //hide columns that dont fit on the table
                    tooltips:true,            //show tool tips on cells
                    addRowPos:"top",          //when adding a new row, add it to the top of the table
                    history:true,             //allow undo and redo actions on the table
                    pagination:"local",       //paginate the data
                    paginationSize:50,         //allow 7 rows per page of data
                    paginationSizeSelector:true,
                    //paginationCounter:"rows", //display count of paginated rows in footer
                    movableColumns:true,      //allow column order to be changed
                    resizableRows:true,       //allow row order to be changed
                    columns:[
                        {title:"編號", field:"device", sorter:"string", headerFilter:true, headerFilterPlaceholder:"篩選編號"},
                        {title:"測站編號", field:"id", sorter:"string", headerFilter:true, headerFilterPlaceholder:"篩選編號"},
                        {title:"經度", field:"lat", sorter:"string", editor:"input"},
                        {title:"緯度", field:"lon", sorter:"string", editor:"input"},
                        {title:"高度", field:"alt", sorter:"string", editor:"input"},
                        {title:"監測地區", field:"area", sorter:"string", editor:"input"},
                        {title:"監測種類", field:"areatype", sorter:"string", editor:"input"},
                        {title:"類型", field:"types", sorter:"string", editor:"select", editorParams:{
                                "1":"第一類",
                                "2":"第二類",
                                "3":"第三類",
                                "4":"第四類",
                                "5":"第五類",
                            }},
                        {title:"靜音", field:"silent", sorter:"string", editor:"select", editorParams:{
                                "0":"關閉",
                                "1":"開啟",
                            }},
                            {title:"修改", field:"opt",tooltip:false,tooltipsHeader:false,formatter:optIconFunction,headerSort:false, cellClick:function(e, cell){
                                var re = cell.getRow(cell); // get the row of the cell that was clicked
                                var allData = re.getData();
                                self.saveTr(allData);
                            }},
                        {title:"更多", field:"opt2",tooltip:false,tooltipsHeader:false,formatter:optIconFunction2,headerSort:false, cellClick:function(e, cell){
                            //console.log("cell click more")
                            //this.dialog_single=true;
                            //this.fetchData;
                            var re = cell.getRow(cell); // get the row of the cell that was clicked
                            var thenum = re.getData().id; // gets the value of column "id" in the same row
                            //var theurl = "editTransaction.php?trans="+thenum; // creates my url
                            //console.log(theurl); // perform whatever action you need to do. replace this.
                            //MyComponent.methods.fetchData(thenum);
                            self.dialog_single=true;
                            self.fetchData(thenum);
                        }},
                    ],
                    
                    //autoColumns:true,
                });
            }

        });
        
        //環保署
        const apiurl_epa=config.geturl(config.API.nearest_epa,{});
        axios.get(apiurl_epa).then(response => {
            $.each(response.data, function(i, item) {
                new_json_epa.push({
                    "name":item.name,
                    "value":item.id
                });
            });
        })

        
        //氣象站
        const apiurl_cwb=config.geturl(config.API.nearest_cwb,{});
        axios.get(apiurl_cwb).then(response => {
            $.each(response.data, function(i, item) {
                new_json_cwb.push({
                    "name":item.name,
                    "value":item.id
                });
            });
        })

        //規則
        const apiurl_rules=config.geturl(config.API.rules_simple_list,{});
        axios.get(apiurl_rules).then(response => {
            $.each(response.data, function(i, item) {
                new_json_rules.push({
                    "name":item.name,
                    "value":item.num
                });
            });
        })
        
    },
    computed: {
        AllFilterSelected () {
        return this.selectedIdName.length === this.selected_id_items.length
        },
        SomeFilterSelected () {
        return this.selectedIdName.length > 0 && !this.AllFilterSelected
        },
        filterIcon () {
            if (this.AllFilterSelected) return mdiCheckboxMarked
            if (this.SomeFilterSelected) return mdiCheckboxBlankOutline
            return mdiCheckboxBlankOutline
        },
       AllRulesSelected () {
            return this.single_station.rules.length === this.single_station.rules_items.length
        },
        SomeRulesSelected () {
            return this.single_station.rules.length > 0 && !this.AllRulesSelected
        },
        filterRulesIcon () {
            if (this.AllRulesSelected) return mdiCheckboxMarked
            if (this.SomeRulesSelected) return mdiCheckboxBlankOutline
            return mdiCheckboxBlankOutline
        },

        AllMultiSelected () {
            return this.selected_val.length === this.colname.subselect.length
        },
        SomeMultiSelected () {
            return this.selected_val.length > 0 && !this.AllMultiSelected
        },
        filterMultiIcon () {
            if (this.AllMultiSelected) return mdiCheckboxMarked
            if (this.SomeMultiSelected) return mdiCheckboxBlankOutline
            return mdiCheckboxBlankOutline
        },
    },
    methods:{
        toggle () {
            this.$nextTick(() => {
                if (this.AllFilterSelected) {
                this.selectedIdName = []
                } else {
                    let trarry = this.selected_id_items.slice();
                    let finalArry=[];
                    if(trarry.length > 0){
                        trarry.forEach(x=>{
                            finalArry.push(x.device);
                        });
                    }
                    this.selectedIdName = finalArry;
                    //this.selectedIdName = this.selected_id_items.slice()
                }
            })
        },
        toggle_rules () {
            this.$nextTick(() => {
                if (this.AllRulesSelected) {
                this.single_station.rules = []
                } else {
                let trarry = this.single_station.rules_items.slice();
                let finalArry=[];
                    if(trarry.length > 0){
                        trarry.forEach(x=>{
                            finalArry.push(x.value);
                        });
                    }
                    this.single_station.rules = finalArry;
                }
            })
        },
        toggle_multi () {
            this.$nextTick(() => {
                if (this.AllMultiSelected) {
                    this.selected_val = []
                } else {
                    let trarry = this.colname.subselect.slice();
                    let finalArry=[];
                    if(trarry.length > 0){
                        trarry.forEach(x=>{
                            finalArry.push(x.value);
                        });
                    }
                    this.selected_val = finalArry;
                    //console.log(this.selected_val);
                }
            })
        },
        colnameChange(a) {
            //先清空
            this.colname.label="";
            this.colname.field="";
            this.colname.subselect=[];
            this.selected_val = "";

            if(a !== null && a !== "" && typeof(a) !== "undefined"){
                var strAry=a.split('-');
                var coltype = strAry[2];
                this.colname.label=strAry[0];
                this.colname.field=strAry[1];

                //場域
                if(coltype == "env"){

                }else if(coltype == "nearest_epa"){ //環保署
                    
                    this.colname.subselect = new_json_epa;

                }else if(coltype == "nearest_cwb"){ //氣象站
                    this.colname.subselect = new_json_cwb;
                }else if(coltype == "rules"){
                    this.selected_val = [];
                    this.colname.subselect = new_json_rules;
                }
            }

            

        },
        fetchData(id){
            var self = this; // assign context to self variable


            //環保署
            this.single_station.nearest_epa = new_json_epa;
            //氣象站
            this.single_station.nearest_cwb = new_json_cwb;
            //規則
            this.single_station.rules_items = new_json_rules;

            var currData = tabledata.filter(item=> item.id == id);
            if(currData.length > 0){
                var first_data= currData[0];

                var ta_nearest_epa=[],ta_nearest_cwb=[],ta_nearest_epa_val="",ta_nearest_cwb_val="";
                var ta_rules=[],ta_rules_val=[];
                //環保署
                if(first_data.nearest_epa == null || first_data.nearest_epa == ""){
                    var town_str = first_data.town;
                    if(first_data.town == "西區")town_str = "忠明";
                    ta_nearest_epa = new_json_epa.filter(item => town_str.indexOf(item.name) > -1);
                    ta_nearest_epa_val = (ta_nearest_epa.length > 0)?ta_nearest_epa[0].value:"";
                }else{
                    ta_nearest_epa_val = first_data.nearest_epa;
                }
                //氣象站
                if(first_data.nearest_cwb == null || first_data.nearest_cwb == ""){
                    ta_nearest_cwb = new_json_cwb.filter(item => first_data.area.indexOf(item.name) > -1 || first_data.road.indexOf(item.name) > -1);
                    ta_nearest_cwb_val = (ta_nearest_cwb.length > 0)?ta_nearest_cwb[0].value:"";
                }else{
                    ta_nearest_cwb_val = first_data.nearest_cwb;
                }
                //規則
                if(first_data.rules == null || first_data.rules == ""){
                    ta_rules = new_json_rules;
                    ta_rules_val =[];
                }else{
                    //ta_rules_val = first_data.rules.trimEnd(',').split(',').map(Number);
                    ta_rules_val = first_data.rules.replace(/(^,)|(,$)/g, "").split(',').map(Number);
                }

                console.log(ta_rules_val);


                this.single_station.num = first_data.num;
                this.single_station.id = first_data.device;
                this.single_station.devicename = first_data.devicename;
                this.single_station.cala = first_data.cala;
                this.single_station.calb = first_data.calb;
                this.single_station.temp_cala = first_data.temp_cala;
                this.single_station.temp_calb = first_data.temp_calb;
                this.single_station.rh_cala = first_data.rh_cala;
                this.single_station.rh_calb = first_data.rh_calb;
                this.single_station.pm10_cala = first_data.pm10_cala;
                this.single_station.pm10_calb = first_data.pm10_calb;
                this.single_station.gid = first_data.gid;
                this.single_station.env = first_data.env;
                this.single_station.town = first_data.town;
                this.single_station.road = first_data.road;
                this.single_station.landmark = first_data.landmark;
                this.single_station.target = first_data.target;
                this.single_station.representative = first_data.representative;
                this.single_station.independent = first_data.independent;
                this.single_station.nearest_epa_selected = ta_nearest_epa_val; //環保署
                this.single_station.nearest_cwb_selected = ta_nearest_cwb_val; //氣象站
                this.single_station.subset = first_data.subset;
                this.single_station.iot_name = first_data.iot_name;
                this.single_station.area = first_data.area;
                this.single_station.areatype = first_data.areatype;
                this.single_station.silent = first_data.silent;
                this.single_station.types = first_data.types;
                this.single_station.lat = first_data.lat;
                this.single_station.lon = first_data.lon;
                this.single_station.alt = first_data.alt;
                this.single_station.rules = ta_rules_val; //規則

            }

        },
        saveTr(obj_json){

                var parseData=obj_json;
                
                const apiurl=config.geturl(config.API.update_single_device + "/Y",{});
                //console.log(parseData);
                axios.post(apiurl, parseData)
                        .then((response) => {
                            if (response.data !== "" && response.data.status == "Y") {
                                //新增/修改成功
                                const apiurl=config.geturl(config.API.infostation,{});
                                axios.get(apiurl).then(response => {
                                    if(response.data !== null && response.data !== ""){
                                        tabledata = response.data;
                                        table.replaceData(tabledata);
                                    }
                                });
                                
                            } else {
                                
                            }
                            alert(response.data.msg);

                        })

        },
        saveSingleDevice(){

            var id =this.single_station.id;
            var currData = tabledata.filter(item=> item.device == id);
            var silent_str = "",types_str="",area_str="",areaType_str="",county_str="";
            if(currData.length > 0){
                var first_data= currData[0];
                silent_str=first_data.silent;
                types_str=first_data.types;
                area_str = first_data.area;
                areaType_str = first_data.areatype;
                county_str = first_data.county;
            }

            var rules_str="";
            if(this.single_station.rules.length > 0){
                rules_str = this.single_station.rules.join(',');
            }

            var parseData = {
                num:this.single_station.num, 
                id:this.single_station.id,
                device:this.single_station.device,
                devicename: this.single_station.devicename,
                area: this.single_station.area,
                areatype: this.single_station.areatype,
                county: county_str,
                cala: this.single_station.cala,
                calb: this.single_station.calb,
                temp_cala: this.single_station.temp_cala,
                temp_calb: this.single_station.temp_calb,
                rh_cala: this.single_station.rh_cala,
                rh_calb: this.single_station.rh_calb,
                pm10_cala: this.single_station.pm10_cala,
                pm10_calb: this.single_station.pm10_calb,
                gid: this.single_station.gid,
                env: this.single_station.env,
                town: this.single_station.town,
                road: this.single_station.road,
                landmark: this.single_station.landmark,
                target: this.single_station.target,
                representative: this.single_station.representative,
                independent: this.single_station.independent,
                nearest_epa: this.single_station.nearest_epa_selected,
                nearest_cwb: this.single_station.nearest_cwb_selected,
                subset: this.single_station.subset,
                iot_name: this.single_station.iot_name,
                silent: this.single_station.silent,
                types: this.single_station.types,
                lat: this.single_station.lat,
                lon: this.single_station.lon,
                alt: this.single_station.alt,
                rules: rules_str
            }
            
           // console.log(parseData);

           const apiurl=config.geturl(config.API.update_single_device+"/N",{});
            axios.post(apiurl, parseData)
                    .then((response) => {
                        if (response.data !== "" && response.data.status == "Y") {
                            //成功
                            this.dialog_single = false;
                            const apiurl=config.geturl(config.API.infostation,{});
                            axios.get(apiurl).then(response => {
                                if(response.data !== null && response.data !== ""){
                                    tabledata = response.data;
                                    table.replaceData(tabledata);
                                }
                            });
                            
                        } else {
                            
                        }
                        alert(response.data.msg);

                    })
        },
        idChangeSelected(){
            if(this.textarea_id_items !== ""){
                var s_arry = this.textarea_id_items.split('\n');
                //將值填入感測器編號
                this.selectedIdName = s_arry;
            }
        },
        saveBatch(){
            var colname = this.selected_field;
            var colval = this.selected_val;
            var ids = this.selectedIdName;
            var ids_str = "";
            //規則
            let split_col = colname.split('-')[2];
            if(split_col == "rules"){
                let arry = this.selected_val;
                colval="";
                for(var jj=0;jj<arry.length;jj++){
                    colval +=arry[jj] + ",";
                }
            }
            for(var i=0;i<ids.length;i++){
                if(i+1==ids.length){
                    ids_str += ids[i];
                }else{
                    ids_str += ids[i] + ",";
                }
            }
            var json_col = colname.split('-')[2];
            //console.log(colname.split('-')[2]);
            //console.log(colval);
            //console.log(ids);

            if(colval == "" || colval == null || typeof(colval) == "undefined"){
                alert("請填寫資料");
            }else if(colname == "" || colname == null || typeof(colname) == "undefined"){
                alert("請選擇欄位");
            }else{
                const apiurl=config.geturl(config.API.update_batch_device,{});
                var parseData = {
                        ids:ids_str,
                        col:json_col,
                        values:colval
                    };

                    
                    axios.post(apiurl, parseData)
                    .then((response) => {
                        if (response.data !== "" && response.data.status == "Y") {
                            //成功
                            this.dialog_batch = false;
                            const apiurl=config.geturl(config.API.infostation,{});
                            axios.get(apiurl).then(response => {
                                if(response.data !== null && response.data !== ""){
                                    tabledata = response.data;
                                    table.replaceData(tabledata);
                                }
                            });
                            
                        } else {
                            
                        }
                        alert(response.data.msg);

                    })
                    .catch(err=>{
                        console.log(err.response);
                    })


            }

        }
    }
}



</script>